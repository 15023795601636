import { useState } from 'react';
import NavbarCz from '../components/NavbarCz/NavbarCz';
import TodosApp from '../components/TodosApp/TodosApp'
import TopControls, { TopControllerActionButtonInterface } from '../components/TopControls/TopControls';
import '../index.scss'
import './Chizzele.scss';
export default function Chizzele() {
    const [shouldRenderApps, setshouldRenderApps] = useState(false);
    const [appLoading, setAppLoading] = useState(false);
    const [currentApp, setCurrentApp] = useState("");
    const [restartTodoApp, setRestartTodoApp] = useState(false);

    function toggleTodoApp() {
      if(currentApp === "todosApp-1.0"){
        setRestartTodoApp(true);
        setTimeout(()=>setRestartTodoApp(false),200);
      }
      setAppLoading(!appLoading);
      setCurrentApp(currentApp === "todosApp-1.0" ? "" : "todosApp-1.0");
      setAppLoading(!appLoading);
      setshouldRenderApps(!shouldRenderApps);
    }

    const todosActionButton: TopControllerActionButtonInterface = {
      action: toggleTodoApp,
      label: <div>Todos</div>,
      type: "action"
    }

    const actionControls:TopControllerActionButtonInterface[] = [
      todosActionButton
    ];

    return (
        <div className="chizzele">
            <TopControls 
              type="rightOnly"
              label=""
              actionControls={actionControls}
            />
            <h1>CHIZZELE</h1>
            {/* Apps Drawer */}
            <div className={`appsDrawer ${shouldRenderApps === true ? 'renderAppsDrawer':''}`}>
              <div className="appsDrawer-fullScreenViewContainer">
                <div className="safeArea">
                  {/* Todo: A simple todo app, pops over the screen and saves to local store */}
                  <TodosApp resetRequested={restartTodoApp}/>
                </div>
              </div>
            </div>

            { /*hiding this navbar to silence warning*/ null ? <NavbarCz/> : null }
        </div>
    )
}
