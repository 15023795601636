import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import './App.scss';
import APP_ROUTES from './App.routes';

function App() {
  return (
    <Router>
      <div className="App">
        <nav style={{display:"none"}}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <ul>
                <li>
                  <Link to="/products/1">First Product</Link>
                </li>
                <li>
                  <Link to="/products/2">Second Product</Link>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <Link to="/users/1">First User</Link>
                </li>
                <li>
                  <Link to="/users/2">Second User</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

      </div>
      <APP_ROUTES/>
    </Router>
  );
}

export default App;
