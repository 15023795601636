import { title } from 'process';
import { useEffect, useState } from 'react';
import { Todo } from '../../TodosApp';

const TodosAddView = (props: TodosAddViewInterface) => {
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  
  function clearForm(){  
    setTitle("");
    setDetails("");
  }
  
  function prepareTodo(): Todo {
    return {
      title: title,
      details: details,
      id: "<todo>",
      complete: false
    }
  }

  return (
    <div>
      <form action="none" target="none">
        <label htmlFor="title">Title</label>
        <br/>
        <input defaultValue={title} onInput={(e)=>setTitle(e.currentTarget.value)} type="text" name="title" id="title" />
        <br/>
        <label htmlFor="details">Details</label>
        <br/>
        <textarea style={{resize:"none", height: "200px"}} defaultValue={details} onInput={(e)=>setDetails(e.currentTarget.value)} name="details" id="details" />
        <br/>
        <button type="button" onClick={()=>props.saveTodo(prepareTodo(), clearForm)}>
          Submit
        </button>
      </form>
    </div>
  );
}

export interface TodosAddViewInterface {
  saveTodo: (todo:Todo, cb:any) => void;
}

export default TodosAddView