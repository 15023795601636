import React from 'react'
import { Route } from 'react-router-dom'
import Chizzele from './Chizzele/Chizzele';
import Workshop from './Workshop/Workshop';

function Index() {
    return <Chizzele/>;
}
  
function Product(path:any) {  
    return <h2>This is a page for product with ID: {path.match.params.id} </h2>;
}

function User(path:any) {  
    return <h2>This is a page for user with ID: {path.match.params.id} </h2>;
}

  
export default function APP_ROUTES() {
    return (
        <div>
            <Route path="/" exact component={Index} />
            <Route path="/chizzele" exact component={Index} />
            <Route path="/workshop" exact component={Workshop} />

            <Route path="/products/:id" component={Product} />
            <Route path="/users/:id" component={User} />
        </div>
    )
}
