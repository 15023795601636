import './TopControls.scss'
const TopControls = (props:TopControlInterface) => {
  function isSingleActionOnly(): boolean {
    let actionControls = [...props.actionControls?props.actionControls :[]];
    if(actionControls.length === 1 && props.type === "leftAndRight"){
      if(actionControls[0].type === "action"){
        return true;  
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  return (
    <div className="topControls">
      {props.type === "rightOnly" || isSingleActionOnly() ? 
        <div className="topControls-rightOnly">
          {
            props.actionControls?.map((control, index) => {
              return (
                <button 
                  key={index}
                  type="button"
                  className="topControls-button"
                  onClick={control.action}
                >
                  {control.label}
                </button>
              )
            })
          }
          <div className="label">{props.label}</div>
        </div>  :
        props.type === "leftAndRight" ? 
        <div className="topControls-leftAndRight">
          {
            props.actionControls?.map((control, index) => {
              return (
                <button 
                  key={index}
                  type="button"
                  className="topControls-button"
                  onClick={control.action}
                >
                  {control.label}
                </button>
              )
            })
          }
        </div> : 
        ""
      }
    </div>
  )
}

export interface TopControlGenericButtonInterface {
  label: HTMLElement | any;
  type: "action" | "navigation";
}

export interface TopControlInterface {
  type: "rightOnly" | "leftAndRight";
  label: string;
  actionControls?:  TopControllerActionButtonInterface[];
  backNavControls?: TopControllerBackNavigateInterface[];
}

export interface TopControllerActionButtonInterface extends TopControlGenericButtonInterface {
  action: (args?:any)=>void;
}

export interface TopControllerBackNavigateInterface extends TopControlGenericButtonInterface {
  whereto: string;
}

export default TopControls