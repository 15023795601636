import React from 'react'
import './Workshop.scss';
export default function Workshop() {
    return (
        <div className="workshop">
          <div>

            <h1>Workshop</h1>
          </div>
            <div className="documents-container">
              <h2>Documents:</h2>
              <ul>
                <li>
                  <a href="/assets/workshop/text.txt" download >
                    sampletext
                  </a>
                </li>
              </ul>
            </div>
        </div>
    )
}
