import { Todo } from "../TodosApp"

const testTodoInComplete: Todo = {
  title: "Test1",
  id: "test01",
  details: "this is a test",
  complete: false
}

const testTodoComplete: Todo = {
  title: "Test2",
  id: "test02",
  details: "this is a second test",
  complete: true
}

const testTodoInCompleted: Todo = {
  title: "Test41",
  id: "test01",
  details: "this is a test",
  complete: false
}

const testTodoCompleted: Todo = {
  title: "Test32",
  id: "test02",
  details: "this is a second test",
  complete: true
}

export const sampleTodos: Todo[] = [testTodoInComplete, testTodoCompleted, testTodoComplete, testTodoInCompleted]