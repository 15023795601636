import { useEffect, useState } from 'react';
import TopControls, { TopControllerActionButtonInterface } from '../TopControls/TopControls';
import TodosTableView from './components/TodosTableView/TodosTableView';
import TodosAddView from './components/TodosAddView/TodosAddView';
import './TodosApp.scss'
import { sampleTodos } from './services/sampleTodos'


const TodosApp = (props:TodosAppInterface) => {
  const todosAddActionButton: TopControllerActionButtonInterface = {
    action: toggleAdding,
    label: <h4>Add</h4>,
    type: "action"
  }

  const todosSaveActionButton: TopControllerActionButtonInterface = {
    action: ()=>console.log('to be implemented'),
    label: <h4>Save</h4>,
    type: "action"
  }

  const todosEditActionButton: TopControllerActionButtonInterface = {
    action: toggleEditing,
    label: <h4>Edit</h4>,
    type: "action"   
  }

  const todosCancelActionButton: TopControllerActionButtonInterface = {
    action: cancelAction,
    label: <h4>Cancel</h4>,
    type: "action"
  }

  const [todos, setTodos] = useState<Todo[]>(sampleTodos);
  const [editing, setEditing] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [contextualActionControls, setContextualActionControls] = useState<TopControllerActionButtonInterface[]>([todosEditActionButton, todosAddActionButton]);
  const [view, setView] = useState<TodoView>("Table");
  // const [resetRequested, setresetRequested] = useState(false);

  
  useEffect(() => {
    return () => {
      if(props.resetRequested === true){
        
        resetUI();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetRequested])


  function toggleComplete(todo:Todo): void {
    const index = todos.findIndex(td=>td===todo);
    let updatedTodos = [...todos];
    updatedTodos[index].complete = !updatedTodos[index].complete;
    setTodos(updatedTodos);
  }

  function deleteTodo(todo:Todo): void {
    const index = todos.findIndex(td=>td===todo);
    let updatedTodos = [...todos];
    updatedTodos.splice(index, 1);    
    setTodos(updatedTodos);
  }

  // flips the value of state property: editing
  function toggleEditing(): void {
    setContextualActionControls([todosCancelActionButton]);
    setView("Table");
    setEditing(!editing);
  }

  // returns states back to normal
  function SaveAction():void {
    setView("Table");
    resetUI();
  }
  

  // returns states back to normal
  function cancelAction():void {
    setView("Table");
    resetUI();
  }
  
  // flips the value of state property: adding
  function toggleAdding(): void {
    setContextualActionControls([todosCancelActionButton, todosSaveActionButton]);
    setView("Add");
    setAdding(!adding);
  }

  //resets UI back to inital states (data will be dependant on source)
  function resetUI(): void {
    setEditing(false);
    setAdding(false);
    if (view === "Add") setView("Table");
    setContextualActionControls([todosEditActionButton, todosAddActionButton]);
  }

  function saveTodo(todo:Todo, cb:any){
    let updatedTodos = [...todos];
    updatedTodos.push(todo);
    setTodos(updatedTodos);
    SaveAction();
    if(cb){
      cb();
    }
  }


  return (
    <div className="todos">
      <TopControls 
        type="leftAndRight"
        label=""
        actionControls={contextualActionControls}
      />
      <h3>Todos</h3>
      <div className="content">
        { view === "Add" ?
          <TodosAddView 
            saveTodo={saveTodo}
          /> : view === "Table" ? 
          <TodosTableView 
            todos={todos}
            editing={editing}
            deleteTodo={deleteTodo}
            toggleComplete={toggleComplete}
          /> : ""
        }
      </div>
    </div>

          
  );
}

export default TodosApp

export interface TodosAppInterface {
  resetRequested : boolean;
}

export type Todo = {
  title: string;
  details: string;
  id: string;
  complete: boolean;
}


export type TodoView =  "Add" | "Table";
