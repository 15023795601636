import { Todo } from "../../TodosApp"

const TodosTableView = (props:TodosTableViewInterace) => {
  return (
    <div className="todos-tableView">
      <table>
          <tbody>
            {props.todos?.map((todo, index) => {
              return (
                <tr key={index}>
                  <td><h5>{todo.title}</h5></td>
                  {
                  /* Context: Viewing */
                  !props.editing ? 
                  <td> 
                    <button type="button" className="todo-gotoAction" onClick={()=>{console.log('go to');
                    }}>  
                      <h5 className="">see details</h5> 
                    </button> 
                  </td> :
                  /* Context: Editing */
                    props.editing ? 
                    <>
                      <td> 
                        <button type="button" className="todo-removeAction" onClick={()=>props.deleteTodo(todo)}>  
                          <h5 className="">delete</h5> 
                        </button> 
                      </td>
                      <td>
                        <input type="checkbox" 
                          name={todo.id} 
                          id={todo.id} 
                          checked={todo.complete} 
                          onChange={()=>props.toggleComplete(todo)}/>
                      </td>
                    </>
                      :
                  /* Context: Unkown */  
                    "default - refresh"
                  }
                  
                  
                </tr>
              )
            })}
          </tbody>
        </table>
    </div>
  ); 
}

export default TodosTableView

export interface TodosTableViewInterace {
  todos?: Todo[];
  editing?: boolean;
  deleteTodo: (todo:Todo) => void;
  toggleComplete: (todo:Todo) => void;

}